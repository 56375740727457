import styles from "./callCenter.module.css";
import React, { useEffect, useRef, useState } from "react";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import { observer } from "mobx-react";
import staticStore from "../stores/staticStore";
import Div100vh from "react-div-100vh";
import agoraStore from "../stores/agoraStore";
import moment from "moment";
import axios from "axios";
import { Redirect } from "react-router-dom";
import socketStore from "../stores/socketStore";
import { customAxios } from "../helpers/axios";
import { Rating } from "react-simple-star-rating";
import useUnloadBeacon from "use-unload-beacon";
import { FallingLines } from "react-loader-spinner";

const BUTTON_DIVIDER = 10;
const BUTTON_IMAGE_DIVIDER = 13;

const MyMessage = ({ message, time }) => {
  const isUrl = isValidUrl(message);
  return (
    <div className={styles.myMessage}>
      {!isUrl ? (
        <span className={styles.myMessageText}>{message}</span>
      ) : (
        <a
          target="_blank"
          className={styles.myMessageText}
          href={message.includes("http") ? message : `http://${message}`}
        >
          {message}
        </a>
      )}
      <span className={styles.messageTime}>{moment(time).format("HH:MM")}</span>
    </div>
  );
};

const isValidUrl = (string) => {
  let url;
  if (string.includes("http")) {
    return true;
  }
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(string);
};

const ToPrompter = observer(() => {
  const { to_prompter } = socketStore;
  if (!to_prompter) return null;

  return (
    <div
      onClick={() => socketStore.setToPrompter(false)}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        zIndex: 89999999,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          minWidth: "350px",
          maxWidth: "40%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ width: "8%", paddingTop: 20 }}
          src={require("../icons/information.png")}
        />
        <p
          style={{
            fontWeight: "500",
            paddingLeft: 40,
            textAlign: "center",
            paddingRight: 40,
            paddingBottom: 20,
          }}
        >
          Beklediğiniz için teşekkür ederiz. Şu an tüm mağaza danışmanlarımız
          farklı müşterilerimize hizmet vermektedir. Müşteri Hizmetleri
          temsilcilerimizin uygunluğunu kontrol ediyoruz.
        </p>
      </div>
    </div>
  );
});

const OtherMessage = ({ message, time }) => {
  const isUrl = isValidUrl(message);
  return (
    <div className={styles.otherMessage}>
      {!isUrl ? (
        <span className={styles.otherMessageText}>{message}</span>
      ) : (
        <a
          target="_blank"
          className={styles.otherMessageText}
          href={message.includes("http") ? message : `http://${message}`}
        >
          {message}
        </a>
      )}
      <span className={styles.otherMessageTime}>
        {moment(time).format("HH:MM")}
      </span>
    </div>
  );
};

const ButtonCloseCamera = observer(({}) => {
  const { isCamera } = agoraStore;
  return (
    <div
      onClick={async () => {
        socketStore.sendMessage({
          data: {
            isCamera,
          },
          type: "camera",
        });
        await agoraStore.setIsCamera();
      }}
      className={styles.buttonBreak}
      style={
        {
          // maxWidth: 40,
          // maxHeight: 40,
          // width: window.innerWidth / BUTTON_DIVIDER,
          // height: window.innerWidth / BUTTON_DIVIDER,
        }
      }
    >
      {isCamera ? (
        <img
          src={require("../icons/camera.png")}
          className={styles.buttonIcon}
          style={{
            maxWidth: 35,
            maxHeight: 35,
            width: window.innerWidth / BUTTON_IMAGE_DIVIDER,
            height: window.innerWidth / BUTTON_IMAGE_DIVIDER,
          }}
        />
      ) : (
        <img
          src={require("../icons/closeCamera.png")}
          className={styles.buttonIcon}
          style={{
            maxWidth: 35,
            maxHeight: 35,
            width: window.innerWidth / BUTTON_IMAGE_DIVIDER,
            height: window.innerWidth / BUTTON_IMAGE_DIVIDER,
          }}
        />
      )}
    </div>
  );
});

const ButtonClose = () => {
  const [redirect, setRedirect] = useState(false);
  const { isCameraOpen } = agoraStore;

  // if(redirect){
  //   return <Redirect to={"/create-call"}/>
  // }

  return (
    <div
      onClick={async () => {
        const k = await customAxios.post(
          "live-store/end",
          {
            slug: socketStore.slug,
          },
          {
            headers: {
              authorization: socketStore.token,
            },
          }
        );
        socketStore.setEndCall(true);
        // setRedirect(true);
      }}
      className={styles.closeButton}
      style={
        {
          // maxWidth: 60,
          // maxHeight: 60,
          // width: window.innerWidth / (BUTTON_DIVIDER - 1),
          // height: window.innerWidth / (BUTTON_DIVIDER - 1),
        }
      }
    >
      <img
        src={require("../icons/closeCall.png")}
        style={{
          maxWidth: 35,
          maxHeight: 35,
          width: window.innerWidth / (BUTTON_IMAGE_DIVIDER - 1),
          height: window.innerWidth / (BUTTON_IMAGE_DIVIDER - 1),
        }}
        className={styles.buttonIcon}
      />
    </div>
  );
};

const NoAnswer = observer(() => {
  const { no_response } = socketStore;
  if (!no_response) return null;

  return (
    <div
      onClick={() => {}}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        zIndex: 89999999,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          minWidth: "350px",
          maxWidth: "40%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ width: "8%", paddingTop: 20 }}
          src={require("../icons/information.png")}
        />
        <p
          style={{
            fontWeight: "500",
            paddingLeft: 40,
            textAlign: "center",
            paddingRight: 40,
            paddingBottom: 20,
          }}
        >
          Çok üzgünüz. Şu an tüm temsilcilerimiz diğer müşterilerimize hizmet
          vermektedir. Canlı mağazamızı daha sonra tekrar ziyaret edebilir,
          danışmanlarımızla görüşme sağlayabilirsiniz.
        </p>
      </div>
    </div>
  );
});

const ButtonSwitchCamera = observer(({}) => {
  const { isCamera } = agoraStore;

  const [cameras, setCameras] = useState([]);
  const [cameraAsked, setCameraAsked] = useState(false);

  if (isCamera && !cameraAsked) {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((t) => {
        const k = t.getTracks();
        setCameras(k);
        setCameraAsked(true);
      });
  }

  if (!isCamera) return null;

  if (cameras.length <= 1) {
    return null;
  }

  return (
    <div
      onClick={async () => {}}
      className={styles.button}
      style={{
        position: "absolute",
        right: 0,
        width: 30,
        height: 30,
        marginRight: 5,
        marginTop: 5,
        borderWidth: 1,
        zIndex: 9,
      }}
    >
      <img
        src={require("../icons/switchCamera.png")}
        style={{ width: 25, height: 25 }}
        className={styles.buttonIcon}
      />
    </div>
  );
});

const ButtonMute = observer(() => {
  const { isMute } = agoraStore;
  return (
    <div
      onClick={async () => {
        agoraStore.setMute();
      }}
      className={styles.buttonBreak}
      style={
        {
          // maxWidth: 40,
          // maxHeight: 40,
          // width: window.innerWidth / BUTTON_DIVIDER,
          // height: window.innerWidth / BUTTON_DIVIDER,
        }
      }
    >
      {isMute ? (
        <img
          src={require("../icons/mute.png")}
          style={{
            maxWidth: 35,
            maxHeight: 35,
            width: window.innerWidth / BUTTON_IMAGE_DIVIDER,
            height: window.innerWidth / BUTTON_IMAGE_DIVIDER,
          }}
          className={styles.buttonIcon}
        />
      ) : (
        <img
          src={require("../icons/unmute.png")}
          style={{
            maxWidth: 35,
            maxHeight: 35,
            width: window.innerWidth / BUTTON_IMAGE_DIVIDER,
            height: window.innerWidth / BUTTON_IMAGE_DIVIDER,
          }}
          className={styles.buttonIcon}
        />
      )}
    </div>
  );
});

const ButtonChat = observer(({ cb, children }) => {
  return (
    <div
      onClick={async () => {
        cb();
      }}
      className={styles.buttonBreak}
      id="chatBtn"
      style={{
        position: "relative",
        // maxWidth: 40,
        // maxHeight: 40,
        // width: window.innerWidth / BUTTON_DIVIDER,
        // height: window.innerWidth / BUTTON_DIVIDER,
      }}
    >
      <img
        src={require("../icons/chat.png")}
        className={styles.buttonIcon}
        style={{
          maxWidth: 35,
          maxHeight: 35,
          width: window.innerWidth / BUTTON_IMAGE_DIVIDER,
          height: window.innerWidth / BUTTON_IMAGE_DIVIDER,
        }}
      />
      {children}
    </div>
  );
});

const ButtonScreenShare = observer(({ callId }) => {
  const { screenShare } = agoraStore;
  return (
    <div
      onClick={async () => {
        if (screenShare) {
          await agoraStore.stopScreenShare();
        } else {
          await agoraStore.shareScreen(callId);
        }
      }}
      className={styles.button}
    >
      {screenShare ? (
        <img
          src={require("../icons/screenShareClose.png")}
          className={styles.buttonIcon}
        />
      ) : (
        <img
          src={require("../icons/screenShare.png")}
          className={styles.buttonIcon}
        />
      )}
    </div>
  );
});

const Suggestion = ({ link, index }) => {
  const [detail, setDetail] = useState(null);
  const [renderLink, setRenderLink] = useState(false);

  useEffect(() => {
    const previewData = async () => {
      try {
        const _detail = await getLinkPreview(link);
        setDetail(_detail);
      } catch (e) {
        setRenderLink(true);
      }
    };
    previewData();
  }, []);

  // if (!detail) return null;
  return (
    <div
      onClick={() => window.open(link, "_blank")}
      className={styles.suggestion}
    >
      {!detail && !renderLink ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FallingLines
            color="#7A63FF"
            width="30"
            visible={true}
            ariaLabel="falling-lines-loading"
            style={{ marginLeft: 120 }}
          />
        </div>
      ) : null}
      {detail && !renderLink ? (
        <img style={{ margin: 7, borderRadius: 10 }} src={detail.images[0]} />
      ) : null}
      {detail && !renderLink ? (
        <span
          style={{
            margin: "auto",
            fontSize: "0.92rem",
            marginLeft: "5px",
            marginRight: "10px",
            fontFamily: "Montserrat-Medium",
          }}
        >
          {detail.title}
        </span>
      ) : null}
      {renderLink && (
        <span
          style={{
            margin: "auto",
            fontSize: "0.92rem",
            marginLeft: "5px",
            marginRight: "10px",
            fontFamily: "Montserrat-Medium",
          }}
        >
          {link.substring(26, 80)}
        </span>
      )}
    </div>
  );
};

const ContentRight = observer(() => {
  const { isMobile } = staticStore;
  const { products } = socketStore;
  if (isMobile || products.length === 0)
    return <div className={styles.contentRight} />;

  return (
    <div className={styles.contentRight}>
      <span
        style={{
          fontWeight: 600,
          fontFamily: "Montserrat",
          marginLeft: "5%",
          color: "#515151",
        }}
      >
        Önerilen Ürünler
      </span>
      {products.map((product, index) => {
        return (
          <Suggestion key={"suggest#" + index} index={index} link={product} />
        );
      })}
    </div>
  );
});

const ArrowForMobile = ({ onClick, showSuggestions }) => {
  const { isMobile } = staticStore;
  if (!isMobile) return null;
  const img_src = showSuggestions
    ? require("../icons/downarrow.webp")
    : require("../icons/uparrow.webp");

  return (
    <div
      onClick={() => {
        onClick();
      }}
      style={{
        zIndex: 99999,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        marginRight: "12px",
        height: 40,
        width: 40,
        display: "flex",
        position: "absolute",
        right: 0,
        bottom: !showSuggestions ? 0 : 130,
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ height: "20px" }} src={img_src} />
    </div>
  );
};

const SuggestionsForMobile = observer(() => {
  const { isMobile } = staticStore;
  if (!isMobile) return null;
  const { products } = socketStore;

  return (
    <div
      className={styles.mobileSuggestContainer}
      style={{ backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <span
          style={{
            fontFamily: "Montserrat",
            fontWeight: 400,
            marginLeft: "12px",
            color: "#515151",
            fontSize: "0.95rem",
          }}
        >
          Önerilen Ürünler
        </span>
      </div>
      <div className={styles.suggestCont}>
        {products
          .slice()
          .reverse()
          .map((product, index) => {
            return <Suggestion key={product} index={index} link={product} />;
          })}
      </div>
    </div>
  );
});

const EndCall = ({ ratingGiven }) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");
  const [isSended, setIsSended] = useState(ratingGiven);

  const handleRating = (rate) => {
    setRating(rate);
  };

  if (isSended) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        Değerli Yorumunuz İçin Teşekkürler
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignSelf: "stretch",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h3
        style={{ color: "black", fontFamily: "Montserrat", fontWeight: "600" }}
      >
        Görüşleriniz bizim için önemli
      </h3>
      <Rating onClick={handleRating} initialValue={rating} />
      <textarea
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        placeholder="Deneyiminizi bizimle paylaşın"
        className={styles.commentInput}
        style={{ width: "40vh", marginTop: 12 }}
      />
      <div
        onClick={async () => {
          await customAxios.post(
            "live-store/rate",
            { rating, comment },
            {
              headers: {
                Authorization: socketStore.token,
              },
            }
          );
          setIsSended(true);
        }}
        className={styles.button}
        style={{
          width: "40vh",
          borderRadius: 8,
          color: "white",
          marginTop: 18,
        }}
      >
        Gönder
      </div>
    </div>
  );
};

const Contents = observer(() => {
  const { screenShare, remote_ratio, isCamera } = agoraStore;
  const { end_call, ratingGiven } = socketStore;

  if (end_call) {
    return (
      <div className={styles.contentMid}>
        <EndCall ratingGiven={ratingGiven} />
      </div>
    );
  }

  return (
    <div className={styles.contentMid}>
      {screenShare ? (
        <div className={styles.screenShare} id="screenShare" />
      ) : null}
      <div
        className={!screenShare ? styles.remote : styles.shareRemote}
        id="remote"
      />
      <div
        className={styles.local}
        style={{ visibility: isCamera ? "visible" : "hidden" }}
        id="local"
      >
        <ButtonSwitchCamera />
      </div>
    </div>
  );
});

const SendMessage = (message) => {
  socketStore.sendMessage(
    {
      message: {
        type: "text",
        content: message,
        time: new Date().getTime(),
      },
      type: "message",
    },
    false
  );
};

const NewMessage = observer(() => {
  const { hasNewMessage } = socketStore;
  if (!hasNewMessage) return null;
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: "red",
      }}
    />
  );
});

const MessageContainer = observer(({ cb }) => {
  const { messages, end_call } = socketStore;
  const { isMobile } = staticStore;
  const [message, setMessage] = useState("");

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatHeader}>
        <div style={{ flex: 1 }} />
        <span style={{ fontSize: "1.2rem", fontWeight: 600 }}>Mesajlaşma</span>
        {!end_call || isMobile ? (
          <div
            onClick={() => cb()}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                flex: 0,
                marginRight: 10,
                backgroundColor: "#FF3C3C",
                display: "flex",
                alignSelf: "flex-end",
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 8,
                paddingBottom: 8,
                borderRadius: 32,
              }}
            >
              <img
                src={require("../icons/close.png")}
                style={{ width: 15, height: 15 }}
              />
            </div>
          </div>
        ) : (
          <div style={{ flex: 1, display: "flex" }} />
        )}
      </div>
      <div
        style={{
          flex: 1,
          width: "100%%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          marginLeft: 8,
          marginRight: 8,
          marginBotton: 50,
          position: "absolute",
          bottom: 40,
          top: 50,
          paddingBottom: 10,
        }}
      >
        {messages
          .slice()
          .reverse()
          .map((message, index) => {
            if (message.user === "remote") {
              return (
                <OtherMessage
                  key={"message#" + index}
                  message={message.text}
                  time={new Date().getTime()}
                />
              );
            }
            return (
              <MyMessage
                key={"message#" + index}
                message={message.text}
                time={new Date().getTime()}
              />
            );
          })}
        {/* {/* <MyMessage message={"Naber kanka nasılsın "} time={new Date().getTime()} /> */}
      </div>
      {!end_call ? (
        <div className={styles.chatInput}>
          <input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (message !== "") {
                  SendMessage(message);
                  setMessage("");
                }
              }
            }}
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles.messageInput}
          />
          <div
            onClick={() => {
              if (message !== "") {
                SendMessage(message);
                setMessage("");
              }
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={require("../icons/sendButton.png")}
              style={{ width: 30, height: 30, marginRight: 8 }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
});

const Demo = observer(({}) => {
  const { remoteId } = agoraStore;
  const { end_call } = socketStore;

  // useUnloadBeacon(`https://staging-api.selffix.co/live-store/end?slug=${socketStore.slug}&authorization=${socketStore.token}`);

  if (remoteId || end_call) return null;

  return (
    <div
      style={{
        flexDirection: "column",
        zIndex: 9999,
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        style={{
          width: "100%",
          minHeight: "600px",
        }}
        // height="315"
        src="https://www.youtube.com/embed/M9j4ifH-tbY?controls=1&mute=1&autoplay=1"
        title="Selffix Video"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <span style={{ color: "white", marginTop: 18 }}>
        Müşteri Temsilcisi Bağlantısı Bekleniyor...
      </span>
    </div>
  );
});

class CallScreen extends React.Component {
  constructor(props) {
    super(props);
    const { isMobile } = staticStore;
    this.state = {
      showSuggestions: !isMobile,
      showChat: true,
      checkIsValid: null,
    };
  }

  unload = async () => {
    const k = await customAxios.post(
      "live-store/end",
      {
        slug: socketStore.slug,
      },
      {
        headers: {
          authorization: socketStore.token,
        },
      }
    );
    socketStore.setEndCall(true);
  };

  componentDidMount() {
    const { match, location } = this.props;
    const token = new URLSearchParams(location.search).get("token");

    const { callId } = match.params;
    agoraStore.init(document).then(async (resp) => {
      await agoraStore.join(callId);
    });

    const body = {
      slug: callId,
      token,
    };

    customAxios.post("live-store/verify", body).then((r) => {
      if (r.data.done && r.data.verified) {
        this.setState({
          checkIsValid: true,
        });
        socketStore.init(token, callId);
      } else {
        return this.setState({
          checkIsValid: false,
        });
      }

      socketStore.setMessagesInit(r.data.messages);
      socketStore.setProductsInit(r.data.products);

      if (r.data.state === "end") {
        socketStore.setEndCall(true);
        socketStore.setRatingGiven(r.data.rating.rating !== -1);
      }
    });
    // window.addEventListener("unload", this.onUnload);
  }

  componentWillUnmount() {
    // window.addEventListener("unload", this.onUnload);
    // this.unload().then(() => {
    // })
  }

  render() {
    const { callId } = this.props.match.params;
    const { showSuggestions, showChat, checkIsValid } = this.state;
    const { end_call } = socketStore;
    const { isMobile } = staticStore;

    if (checkIsValid === false) {
      return <Redirect to={"/404"} />;
    }

    return (
      <Div100vh className={styles.container}>
        {/* <div className={styles.header}>
          <img className={styles.selffixIcon} src={require("../icons/selffix.png")} />
        </div> */}
        <div className={styles.content}>
          <div className={styles.contentLeft}>
            {!showChat ? (
              <MessageContainer
                cb={() => this.setState({ showChat: !showChat })}
              />
            ) : null}
          </div>
          <Contents />
          <ContentRight />
        </div>
        <div className={styles.footer}>
          <div className={styles.fotterLeft}></div>

          <div className={styles.fotterMid}>
            {/* <ButtonScreenShare callId={this.props.match.params.callId} /> */}
            <ButtonChat
              cb={() => {
                this.setState({ showChat: !showChat });
                socketStore.setHasNewMessage(false);
              }}
            >
              {showChat ? <NewMessage /> : null}
            </ButtonChat>
            {!end_call && <ButtonCloseCamera />}
            {!end_call && <ButtonClose />}
            {!end_call && <ButtonMute />}
            {!end_call && (
              <div
                className={styles.button}
                style={{
                  // visibility: "hidden",
                  display: "none",
                }}
              ></div>
            )}
          </div>

          <div className={styles.fotterRight}></div>
          {showSuggestions ? <SuggestionsForMobile /> : null}
          <ArrowForMobile
            showSuggestions={showSuggestions}
            onClick={() => this.setState({ showSuggestions: !showSuggestions })}
          />
        </div>

        <Demo />

        <ToPrompter />
        <NoAnswer />
      </Div100vh>
    );
  }
}

export default observer(CallScreen);
